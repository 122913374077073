import BaseModelAPI from "../BaseModelAPI";
import axiosClient, { axiosOuter } from "../axiosClient";
import { API_DIST_MODEL } from "./const";

class MicroDistrictApiRequest extends BaseModelAPI {
    constructor() {
        super(API_DIST_MODEL.url);
    }

    async mapApi(id, urlparams) {
        return this.makeRequest(axiosClient.get, { id: id, method: API_DIST_MODEL.methods.map.url, urlParams: urlparams });
    }
    async landplotsApi(id, urlparams) {
        return this.makeRequest(axiosClient.get, { id: id, method: API_DIST_MODEL.methods.landplots.url, urlParams: urlparams });
    }
    async landplotsOptions(id) {
        return this.makeRequest(axiosOuter.get, { id: id, method: API_DIST_MODEL.methods.landplot_options.url });
    }
    
    async mapApiFilter(id, urlparams) {
        return this.makeRequest(axiosClient.get, { id: id, method: API_DIST_MODEL.methods.map.url, urlParams: urlparams });
    }
    async microdistrictOptions(id) {
        return this.makeRequest(axiosClient.options, { id: id, method: API_DIST_MODEL.methods.map.url });
    }

    async microdistrictMap(id) {
        return this.makeRequest(axiosClient.options, { id: id, method: API_DIST_MODEL.methods.map.url });
    }
    async dischargeMunic(data) {
        return this.makeRequest(axiosClient.post, { method: API_DIST_MODEL.methods.import_municipalities.url, body: data });
    }
    async dischargeMicrodist(data) {
        return this.makeRequest(axiosClient.post, { method: API_DIST_MODEL.methods.import_microdistrict.url, body: data });
    }
    async dischargeMicrodistName(data) {
        return this.makeRequest(axiosClient.post, { method: API_DIST_MODEL.methods.fix_name_microdistrict.url, body: data });
    }
}

export default MicroDistrictApiRequest;
