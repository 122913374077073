import React from "react"

function TextareaItem(props) {
    return (
        <div className={"form__item " + props.classes}>
            <span className="error-message">{(props.fieldDirty && props.error) && props.error}</span>
            <textarea id={props.field.id}
                      name={props.field.name}
                      value={props.value}
                      onChange={e => props.fieldHandler(e)}
                      onBlur={props.blurHandle}
                      placeholder={props.field.placeholder}
                      required={props.required}
                      readOnly={props.readOnly}></textarea>
            <label htmlFor={props.field.name}>{props.field.label}{props.field.required || props.required ? <span>*</span> : ''}</label>
        </div>
    )
}

export default TextareaItem