export default function error(data) {
    try {
        if (data.data.response.status >= 500) {
            return 'Ошибка сервера, повторите попытку позже'
        } else if (data.data.response.status >= 400) {
            return data.data.response.data?.error || 'Ошибка ввода данных'
        } else {
            console.log('error', data)
            return data.data.response.data?.error || 'Возникла непредвиденная ошибка'
        }
    } catch {
        console.log('error', data)
        return 'Возникла непредвиденная ошибка'
    }
}