import React from 'react'

import FormPage from '../../components/FormPage/FormPage'

const BIKInvestPage = () => {
    return (
        <FormPage>
            Получить займ КПК БИК-Инвест
        </FormPage>
    )
}

export default BIKInvestPage