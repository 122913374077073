import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux"

import FormItem from '../../UI/fields/FormItem'
import { setUserDataItem } from '../../../store/userDataSlice'
import urls from '../../../urls';
import CabinetRequest from '../../../api/cabinet/Cabinet';
import error from '../../../functions/error';
import { API_CABINET_MODEL } from '../../../api/cabinet/const';


const LoginCodeForm = ({ contragentActions }) => {
  const cabinet = new CabinetRequest()

  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(59);
  const [resendCode, setResendCode] = useState(false)

  const userData = useSelector(state => state.user_data.userData)
  const dispatch = useDispatch()
  const addUserData = (name, value) => dispatch(setUserDataItem([name, value]))
  const [formError, setFormError] = useState(false)
  const [btnActive, setBtnActive] = useState(false)
  const [fields, setFields] = useState([
    {
      id: 'username',
      name: 'username',
      type: 'hidden',
    },
    {
      id: 'password',
      name: 'password',
      type: 'text',
      label: 'Код из СМС'
    },
  ])
  const [data, setData] = useState({
    username: userData.user_login,
  })
  const navigate = useNavigate();

  const setTime = () => {
    setTimeout(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      } else {
        setSeconds(59)
        setMinutes(minutes - 1)
      }
    }, 1000)
  }

  useEffect(() => {
    if (minutes > 0 || seconds > 0)
      setTime()
    else
      setResendCode(true)
  }, [minutes, seconds])

  const setValue = (name, value) => {
    if (value) {
      data[name] = value
      //console.log('setValue', value)
    } else {
      delete data[name]
    }
    setData({ ...data })
    if (data.password) {
      setBtnActive(true)
    } else {
      setBtnActive(false)
    }
  }

  const setError = (name, value) => {
    const index = fields.findIndex(field => field.name === name)
    fields[index].error = value
    setFields(fields)
  }

  const send_code = () => {
    setMinutes(1)
    setSeconds(59)
    cabinet.sendCode({ phone: userData.user_login })
      .then(data => {
        //console.log('response', data)
        if (data.success) {
          setResendCode(false)
          //navigate('/code_accept')
        } else {
          setFormError(error(data))
        }
      })
  }

  const login = () => {
    setBtnActive(false)
    cabinet.login(data)
      .then(data => {

        if (data.success) {
          addUserData('token', data.data.access)
          addUserData('refresh', data.data.refresh)

            
          if (data.data.active && data.data.contragent) { // есть кабинет и контранент в коллективе

            addUserData('contragent', data.data.contragent[0].id)
            if (contragentActions) {
              contragentActions.pass(data.data.contragent[0].id) // создается задача
            } else {
              navigate(urls.cabinet_page.path) // редирект в кабинет
            }

          } else {

            if (data.data.contragent) {// есть только контранент в коллективе

              addUserData('contragent', data.data.contragent[0].id)
              if (contragentActions) {
                contragentActions.pass(data.data.contragent[0].id) // создается задача
              } else {
                navigate(urls.register.path) // редирект в регистрацию , { state: { contragent: JSON.stringify(data.data.contragent) } } 
              }

            } else { // нет контранена и/или кабинета

              if (contragentActions) {
                contragentActions.create()
              } else {
                navigate(urls.register.path)
              }
            }
          }

          // ошибка
        } else {
          setFormError(error(data))
        }
        setBtnActive(true)
      })
  }

  return (
    <>
      <h1 className='form-card__title-24'>Введите код из СМС</h1>
      {!resendCode ? <p className='form-card__description'>Запросить повторно через {minutes}:{seconds}</p>
        : <button onClick={send_code} className='cabinet-btn mb-4'>Отправить код снова</button>
      }
      {formError && <p className='form__error'>{formError}</p>}

      {fields.map(item => (
        <div>
          <FormItem field={item} key={item.id} mask={item.mask || false} value={data[item.name]} setValue={setValue} setError={setError} />
        </div>
      ))}
      <span className={`form-btn _block mt-16 ${btnActive ? '' : '_disabled'}`} onClick={login}>Отправить</span>
    </>
  )
}

export default LoginCodeForm