import BIK from './servise_cards/home.svg'
import Market from './servise_cards/shopping-bag.svg'
import Fond from './servise_cards/briefcase.svg'
import Wallet from './servise_cards/wallet.svg'
import ArrowLeft from './arrow-narrow-left.svg'
import Camera from './servise_cards/camera.svg'
import Home from './servise_cards/home-03.svg'
import Bank from './servise_cards/bank.svg'
import Chart from './servise_cards/chart-circle.svg'
import UserRight from './servise_cards/users-right.svg'
import Layers from './servise_cards/layers.svg'
import Calendar from './servise_cards/calendar.svg'
import Droplets from './servise_cards/droplets.svg'
import Mail from './servise_cards/mail.svg'
import CoinsHand from './servise_cards/coins-hand.svg'
import FileQuestion from './servise_cards/file-question.svg'
import AlertSquare from './servise_cards/alert-square.svg'
import Gift from './servise_cards/gift.svg'
import Sell_land from './servise_cards/sell_land.svg'

export default {
    Sell_land,
    Gift,
    AlertSquare,
    FileQuestion,
    CoinsHand,
    Mail,
    Droplets,
    Calendar,
    Layers,
    UserRight,
    Chart,
    Bank,
    Home,
    Camera,
    BIK,
    Market,
    Fond,
    Wallet,
    ArrowLeft
}