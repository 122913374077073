import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux"

import FormItem from '../../UI/fields/FormItem'
import { onDateTimeInput, onPhoneInput } from '../../../functions/mask'
import { requestAPI } from '../../../api/requestAPI';
import { setUserDataItem } from '../../../store/userDataSlice'
import urls from '../../../urls';
import apiConfig from '../../../api/apiConfig';
import { API_CABINET_MODEL } from '../../../api/cabinet/const';
import ContragentRequest from '../../../api/cabinet/Contragent';
import error from '../../../functions/error';



const RegistrationForm = () => {
    const contragentRequest = new ContragentRequest()

    const userData = useSelector(state => state.user_data.userData)
    const dispatch = useDispatch()
    const addUserData = (name, value) => dispatch(setUserDataItem([name, value]))
    const [btnActive, setBtnActive] = useState(false)
    // const location = useLocation();
    const [formError, setFormError] = useState(false)
    const [fields, setFields] = useState([
        {
            id: 'fio',
            name: 'fio',
            type: 'text',
            label: 'ФИО',
            required: true,
        },
        {
            id: 'email',
            name: 'email',
            type: 'email',
            label: 'Электронная почта',
            required: true,
        },
        {
            id: 'birth_date',
            name: 'birth_date',
            type: 'text',
            label: 'Дата рождения',
            required: true,
            mask: onDateTimeInput,
        },
        {
            id: 'soglashenie',
            name: 'soglashenie',
            type: 'boolean',
            label: <>Я соглашаюсь с <a href={urls.soglashenie.path} target='_blank'>Пользовательским соглашение и политикой обработки персональных данных</a></>,
            required: true,
        }
    ])

    const [data, setData] = useState({
        phone: userData.user_login,
        status: "Физическое лицо"
    })

    const navigate = useNavigate();

    // useEffect(() => {
    //     console.log('location.state', location.state)
    // }, [])

    const setValue = (name, value) => {
        data[name] = value
        value ?? delete data[name]
        if (value === '')
            delete data[name]
        setData({ ...data })
        if (Object.keys(data).length >= fields.length) {
            if (data.soglashenie)
                setBtnActive(true)
            else
                setBtnActive(false)
        } else {
            setBtnActive(false)
        }
    }

    const setError = (name, value) => {
        const index = fields.findIndex(field => field.name === name)
        fields[index].error = value
        setFields(fields)
    }

    const afterRequest = (data) => {
        if (data.success) {
            if (data.contragent)
                addUserData('contragent', data.contragent[0].id)
            navigate(urls.cabinet_page.path)
        } else {
            setFormError('Ошибка ' + data.message)
        }
        setBtnActive(true)
    }

    const send = () => {
        setBtnActive(false)
        const form_data = data

        // if (location.state) {
        if (userData.contragent) {
            //form_data.id = JSON.parse(location.state.contragent).id
            form_data.id = userData.contragent
            contragentRequest.update({ "contragent": form_data })
                .then(response => {
                    //console.log('contragentRequest.update', response)
                    if (response.success) {
                        if (response.data.contragent)
                            addUserData('contragent', response.data.contragent[0].id)
                        setTimeout(() => {
                            navigate(urls.cabinet_page.path)
                        }, 300)
                    } else {
                        setFormError(error(response))
                    }
                    setBtnActive(true)
                })
        } else {
            contragentRequest.create({ "contragent": form_data })
                .then(response => {
                    //('contragentRequest.create', response)
                    if (response.success) {
                        if (response.data.data.id)
                            addUserData('contragent', response.data.data.id)
                        setTimeout(() => {
                            navigate(urls.cabinet_page.path)
                        }, 300)

                    } else {
                        setFormError(error(response))
                    }
                    setBtnActive(true)
                })
        }
    }

    return (
        <>
            {formError && <p className='form__error'>{formError}</p>}
            {fields.map(item => (
                <FormItem field={item} key={item.id} mask={item.mask || false} value={data[item.name]} setValue={setValue} setError={setError} />
            ))}
            <span className={`form-btn _block mt-16 ${btnActive ? '' : '_disabled'}`} onClick={send}>Отправить</span>
        </>
    )
}

export default RegistrationForm