import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    needsRerender: false,
};

const needsRerenderSlice = createSlice({
    name: 'needsRerender',
    initialState,
    reducers: {
        setNeedsRerender: (state, action) => {
            state.needsRerender = action.payload;
        },
    },
});

export const { setNeedsRerender } = needsRerenderSlice.actions;

export default needsRerenderSlice.reducer;