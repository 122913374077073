import React from 'react'

import Header from '../Header/Header'
import FormContainer from '../FormContainer/FormContainer'
import Footer from '../Footer/Footer'

const FormPage = ({ children }) => {
  return (
    <>
      <Header />
      <FormContainer>
          {children}
      </FormContainer>
      <Footer />
    </>
  )
}

export default FormPage