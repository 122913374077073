import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const RedirectPage = ({to}) => {
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        navigate(to + location.search)
    }, [])

  return (
    <div>RedirectPage</div>
  )
}

export default RedirectPage