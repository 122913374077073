import React from 'react'

import FormPage from '../../components/FormPage/FormPage'
import RegistrationForm from '../../components/forms/RegistrationForm/RegistrationForm'

const RegistrationPage = () => {
  
  return (
    <FormPage>
      <h1 className='form-card__title'>Регистрация</h1>
      <p className='form-card__description'>Выберите действие</p>
      <RegistrationForm />
    </FormPage>
  )
}

export default RegistrationPage