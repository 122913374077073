import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import FormItem from '../../UI/fields/FormItem'

import { onPhoneInput, onDateTimeInput } from '../../../functions/mask'
import ContragentRequest from '../../../api/cabinet/Contragent';
import TaskRequest from '../../../api/cabinet/Task'
import { useDispatch, useSelector } from 'react-redux';
import { setUserDataItem } from '../../../store/userDataSlice';
import CabinetRequest from '../../../api/cabinet/Cabinet';
import LoginCodeForm from '../LoginCodeForm/LoginCodeForm';
import MicroDistrictApiRequest from '../../../api/microDistrict/MicroDistrict';
import Loader from '../../UI/Loader/Loader';
import urls from '../../../urls';
import error from '../../../functions/error';

const BuyLandForm = ({ title, activeLandploat }) => {
    const contragentRequest = new ContragentRequest()
    const taskRequest = new TaskRequest()
    const cabinet = new CabinetRequest()
    const mapRequest = new MicroDistrictApiRequest()

    const userData = useSelector(state => state.user_data.userData)
    const dispatch = useDispatch()
    const addUserData = (name, value) => dispatch(setUserDataItem([name, value]))
    const mapData = useSelector(state => state.map_data.mapData)

    const [formSuccess, setFormSuccess] = useState(false)
    const [formLoader, setFormLoader] = useState(false)
    const [sendCode, setSendCode] = useState(false)

    const [btnActive, setBtnActive] = useState(false)
    const navigate = useNavigate();
    const [formError, setFormError] = useState('')
    const [errors, setErrors] = useState({})
    const [oldDataContragent, setOldDataContragent] = useState({})
    const [dataContragent, setDataContragent] = useState({
        status: "Физическое лицо"
    })
    const [dataContragentToSend, setDataContragentToSend] = useState({})
    const [data, setData] = useState({
        landplot: activeLandploat.id,
        type: activeLandploat.type,
        department: 11, // отдел продаж
        category: 1, // Купить участок в БИК
        contragent: userData.contragent ?? null, // contragent_id с коллектива а не с бика !!
        preferencial: []
    })
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)

    const [fieldsContragent, setFieldsContragent] = useState([
        {
            name: 'fio',
            type: 'text',
            label: 'ФИО',
            required: true,
            read_only: !!userData.contragent
        },
        {
            name: 'birth_date',
            type: 'text',
            label: 'Дата рождения',
            placeholder: 'ДД.ММ.ГГГГ',
            mask: onDateTimeInput,
            required: true,
        },
        {
            name: 'email',
            type: 'email',
            label: 'Электронная почта',
            required: true,
        },
        {
            name: 'phone',
            type: 'text',
            label: 'Телефон',
            placeholder: '+7',
            mask: onPhoneInput,
            required: true,
            read_only: !!userData.contragent
        },
    ])


    const [fields, setFields] = useState([
        {
            id: 'preferencial',
            name: 'preferencial',
            type: params.get('type') === 'COMMERCIAL_PLOTS' ? 'hidden': 'checkbox',
            label: '',
            choices: []
        },
        {
            id: 'obrabotka',
            name: 'obrabotka',
            type: 'boolean',
            label: <>Даю согласие на <a href={urls.soglashenie.path} target='_blank'>обработку персональных данных</a></>,
            required: true,
        },
        {
            id: 'department',
            name: 'department',
            type: 'hidden'
        },
        {
            id: 'category',
            name: 'category',
            type: 'hidden'
        },
        {
            id: 'contragent',
            name: 'contragent',
            type: 'hidden',
        }
    ])

    useEffect(() => {
        if (userData.contragent) {
            taskRequest.options()
                .then(resp => {
                    //console.log('task options', resp);
                })
            contragentRequest.getById(userData.contragent).then(response => {
                if (response.success) {
                    for (let key in response.data.data) {
                        if (key)
                            dataContragent[key] = response.data.data[key]
                        setDataContragent({ ...dataContragent })
                        setOldDataContragent({ ...dataContragent })
                    }
                } else {
                    if (response.data.response.status === 500) {
                        setFormError('Ошибка сервера, попробуйте зайти позже')
                    } else {
                        setFormError(response.message)
                    }
                }
            })
        } else {
            console.log('не залогинен')
        }
        if (params.get('type') !== 'COMMERCIAL_PLOTS') {
            taskRequest.preferencial()
                .then(response => {
                    if (response.success) {
                        fields.filter(item => item.name === 'preferencial')[0].choices = response.data.data
                        setFields([...fields])
                    } else {
                        setFormError(error(response))
                    }
                })
        }
    }, [])

    const setContragentValue = (name, value) => {
        dataContragent[name] = value
        value ?? delete dataContragent[name]
        if (value === '')
            delete dataContragent[name]

        setDataContragent({ ...dataContragent })

        if (Object.keys(dataContragent).length >= fieldsContragent.length) {
            if (data.obrabotka)
                setBtnActive(true)
            else
                setBtnActive(false)
        } else {
            setBtnActive(false)
        }
    }

    const setValue = (name, value) => {

        data[name] = value
        value ?? delete data[name]
        if (value === '')
            delete data[name]

        setData({ ...data })


        if (Object.keys(dataContragent).length >= fieldsContragent.length && Object.keys(data).length >= fields.length) {
            if (data.obrabotka)
                setBtnActive(true)
            else
                setBtnActive(false)
        } else {
            setBtnActive(false)
        }
    }

    const setError = (name, error) => {
        if (error) {
            errors[name] = error
        } else {
            try {
                delete errors[name]
            } catch { }
        }
        setErrors({ ...errors })
    }

    const validation = () => {
        let state = {}
        for (const field of fields) {
            if (field.required) {
                if (data[field.name]) {
                    if (field.mask) {
                        field.mask(field.name, data[field.name])
                    } else {
                        return true
                    }
                } else {
                    return 'Поле обязательно для заролнения'
                }
            }
        }
        return true
    }

    const taskCreate = (contragent = null) => {
        if (contragent) {
            data.contragent = contragent
            setData(data)
        }
        taskRequest.create({ task: data })
            .then(response => {
                if (response.success) {
                    setFormSuccess(true)
                } else {
                    // console.log('response', response)
                    // console.log('response', response.data.response.data.data.message)
                    const status = response.data?.response?.data?.data?.message || response.data?.response?.data?.data?.landplot[0]
                 
                    if (status) {
                        setFormError(status)
                    } else {
                        setFormError(error(response))
                    }
                }
                setFormLoader(false)
            })

    }

    const createContragent = () => {
        contragentRequest.create({ contragent: dataContragent })
            .then(response => {
                if (response.success) {
                    console.log('createContragent', response.success)
                    if (response.data.data.id) {
                        addUserData('contragent', response.data.data.id)
                        taskCreate(response.data.data.id)
                    } else {
                        setFormError('Ошибка: в ответе нет контрагента ')
                    }
                } else {
                    setFormError(error(response))
                }
                setFormLoader(false)
            })
    }

    const updateContragent = () => {
        contragentRequest.update({ contragent: { ...dataContragentToSend, id: userData.contragent } })
            .then(data => {
                if (data.success) {
                    if (data.data.data.id) {
                        addUserData('contragent', data.data.data.id)
                        taskCreate(data.data.data.id)
                    } else {
                        setFormError('Ошибка: в ответе нет контрагента ')
                    }
                } else {
                    setFormLoader(false)
                    setFormError(error(data))
                }
                setFormLoader(false)
            })
    }

    const sendData = () => {
        setFormLoader(true)
        addUserData('user_login', dataContragent.phone)
        if (userData.contragent) {
            let contragent_changed = false
            for (const key in dataContragent) {
                if (dataContragent[key] !== oldDataContragent[key]) {
                    dataContragentToSend[key] = dataContragent[key]
                    contragent_changed = true
                }
            }

            setDataContragentToSend({ ...dataContragentToSend })

            if (contragent_changed) {
                updateContragent()
            } else {
                taskCreate()
            }
        } else {
            cabinet.sendCode({ phone: dataContragent.phone })
                .then(response => {
                    //console.log('response', response)
                    if (response.success) {
                        setFormLoader(false)
                        setSendCode(true)
                    } else {
                        setFormLoader(false)
                        setFormError(error(response))
                    }
                })
        }
    }

    const nope = () => {
        alert('Очень хитро, но поля заполнить все равно придется)')
    }

    return (
        <div className="buy-land__form">
            {formLoader ? <Loader /> :
                formSuccess ?
                    <div>
                        <h2 className='success__title'>Заявка на бронирование отправлена</h2>
                        <p className='success__description'>Мы получили вашу заявку и передали ее на модерацию. Специалисты БИК проверят данные, которые вы указали. Проверить статус заявки можно в личном кабинете.</p>
                        <p className='success__subtitle'>Спасибо за то, что выбрали БИК!</p>
                        <button className='success__btn' onClick={() => navigate(urls.cabinet_page.path)}>Перейти в личный кабинет</button>
                    </div> :
                    sendCode ?
                        <>
                            <LoginCodeForm contragentActions={{ pass: taskCreate, create: createContragent, update: updateContragent }} />
                            {formError && <p className='form__error'>{formError}</p>}
                        </> :
                        <>
                            <span className="form__title">{title ?? 'Данные для бронирования'}</span>
                            <p className="form__description">В случае указания заявителем некорректного номера телефона или адреса электронной почты, бронь не будет подтверждена и утратит силу</p>
                            {formError && <p className='form__error'>{formError}</p>}
                            {fieldsContragent.map(item => (
                                <div>
                                    <FormItem
                                        key={`userData_${item.name}`}
                                        field={item}
                                        mask={item.mask}
                                        value={dataContragent[item.name] || ''}
                                        setValue={setContragentValue}
                                        error={errors[item.name] || false}
                                        setError={setError} />
                                </div>
                            ))}

                            {fields.map(item => (
                                <div>
                                    <FormItem
                                        key={`data_${item.name}`}
                                        field={item}
                                        mask={item.mask}
                                        value={data[item.name] || ''}
                                        setValue={setValue}
                                        error={errors[item.name] || false}
                                        setError={setError} />
                                </div>
                            ))}

                            <span className={`form-btn _block mt-16 ${btnActive ? '' : '_disabled'}`} onClick={btnActive ? sendData : nope}>Забронировать</span>
                        </>
            }
        </div>
    )
}

export default BuyLandForm