import React from 'react'

import Header from '../Header/Header'
import FormContainer from '../FormContainer/FormContainer'
import Footer from '../Footer/Footer'

const SimplePage = ({ children }) => {
  return (
    <>
      <Header />
      <div className='content ' style={{alignItems: 'flex-start'}}>
        <div className='container'>
          {children}
        </div>
      </div >
      <Footer />
    </>
  )
}

export default SimplePage