import React from 'react'
import FormPage from '../../components/FormPage/FormPage'
import UserDataForm from '../../components/forms/UserDataForm/UserDataForm'

const EditUserData = ({title}) => {
  return (
    <FormPage>
        <UserDataForm title={title} />
    </FormPage>
  )
}

export default EditUserData