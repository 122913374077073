import { createSlice } from '@reduxjs/toolkit';

const dataMicrodistState = {};

export const dataMicrodist = createSlice({
    name: 'data_microdist',
    initialState: dataMicrodistState,
    reducers: {
        setDataPressItem(state, action) {
            state[action.payload[0]] = action.payload[1];
        },
        clearDataMicrodist(state) {
            // Обнуляем все данные в срезе
            return {};
        },
    },
});

export const { setDataPressItem, clearDataMicrodist } = dataMicrodist.actions;

export default dataMicrodist.reducer;
