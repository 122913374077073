import React, { useState, useEffect } from "react"

import RadioItem from "./RadioItem";
import CheckboxItem from "./CheckboxItem";
import Boolean from "./Boolean";
import SelectItem from "./SelectItem";
import FileItem from "./FileItem";
import TextareaItem from "./TextareaItem";
import './fields.scss'
import TextItem from "./TextItem";
import DateItem from "./DateItem";

const FormItem = (props) => {
    const [fieldDirty, setFieldDirty] = useState(props.field.error ? true : false)
        , required = props.required ? props.required : props.field.required || false
        , readOnly = props.read_only ? props.read_only : props.field.read_only || false


    const setError = (str) => {
        props.setError(props.field.name, str)
    }

    const setData = (val) => {
        props.setValue(props.field.name, val)
    }

    const blurHandle = e => {
        if (required || props.value?.length > 0)
            setFieldDirty(true)
        if (!required && props.value?.length === 0)
            setFieldDirty(false)
        if (required === true && props.value?.length === 0)
            setError('Заполните поле')
    }

    const fieldHandler = e => {
        const val = e.target.value
        setError('')
        setData(val)
        if (required === true) {
            if (val.length === 0) {
                setError('Заполните поле')
            } else {
                setError(false)
            }
        }

    }

    const mask = e => {
        const new_val = props.mask(e.target, setError)
        setData(new_val)
    }

    const [label, setLabel] = useState('');

    const handleInitiator = (value) => {
        setLabel(value);
    };

    useEffect(() => {
        if (typeof props.initiator === 'function') {
            props.initiator(label);
        }
    }, [label]);

    switch (props.field.type) {
        case "hidden":
            return <input type={props.field.type} name={props.field.name} value={props.value} onChange={() => {}} />
        case "boolean":
            return <Boolean {...props} required={required} readOnly={readOnly} />
        case "checkbox":
            return <CheckboxItem {...props} required={required} readOnly={readOnly} />
        case "radio":
            return <RadioItem {...props} required={required} readOnly={readOnly} />
        case "select":
            return <SelectItem {...props} required={required} readOnly={readOnly} initiator={handleInitiator} />
        case "file":
            return <FileItem {...props} required={required} readOnly={readOnly} />
        case "textarea":
            return <TextareaItem {...props} fieldHandler={fieldHandler} required={required} blurHandle={blurHandle} readOnly={readOnly} />
        case "date" || "datetime":
            return <DateItem {...props} fieldDirty={fieldDirty} blurHandle={blurHandle} readOnly={readOnly} setValue={setData} setError={setError} />
        default:
            return <TextItem {...props} handler={props.mask ? mask : fieldHandler} required={required} blurHandle={blurHandle} readOnly={readOnly} />
    }

}

export default FormItem