import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'

import './BuyLandPage.scss'
import SimplePage from '../../components/SimplePage/SimplePage'
import urls from '../../urls.js'
import MapComponents from '../../components/MapPage/MapComponents/MapComponents';
import BuyLandForm from '../../components/forms/BuyLandForm/BuyLandForm';
import Loader from '../../components/UI/Loader/Loader.jsx';
import MicroDistrictApiRequest from '../../api/microDistrict/MicroDistrict.js';
import { setCoordinatesItem, setLandplotList, setSelectedLandploat } from '../../store/mapDataSlice.js';
import icons from '../../assets/icons/icons.jsx';


const BuyLandPage = ({ title }) => {
    const [loading, setLoading] = useState(true)

    const micropdistPlotInstance = new MicroDistrictApiRequest();

    const dispatch = useDispatch()
    const setCoordinates = array => dispatch(setCoordinatesItem(array))
    const setLandplots = array => dispatch(setLandplotList(array))
    const setLandPloat = (obj) => dispatch(setSelectedLandploat(obj))

    const mapData = useSelector(state => state.map_data.mapData)
    const navigate = useNavigate()

    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)
    
    const formatter = new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',
        maximumFractionDigits: 0,
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    useEffect(() => {

        if (mapData.selectedLandploat) {
            setLoading(false)
        } else {
            const landplot_id = params.get('landplot')
            if (landplot_id) {
                micropdistPlotInstance.landplotsApi(params.get('district'), `/?type=${params.get('type')}`)
                    .then((resp) => {
                        if (resp.success) {
                            //console.log('resp', resp)
                            const reversedCoordinates = resp.data.data.coordinates?.map((item) => item);
                            setLandplots([])
                            setCoordinates([])
                            setLandplots([...resp.data.data?.land_plots])
                            setCoordinates([...reversedCoordinates])
                            const landplot = resp.data.data?.land_plots?.filter(item => +item.id === +landplot_id)
                            if (landplot.length > 0) {
                                setLandPloat(landplot[0])
                            } else {
                                navigate(urls.buy_land.path + '?type=' + params.get('type') + '&municipality=' + params.get('municipality') + '&district=' + params.get('district'))
                            }
                        } else {
                            //console.log('landplotsApi error', resp);
                            navigate(urls.buy_land.path + '?type=' + params.get('type') + '&municipality=' + params.get('municipality') + '&district=' + params.get('district'))

                        }
                        setLoading(false)
                    })
            } else {
                navigate(urls.buy_land.path + '?type=' + params.get('type') + '&municipality=' + params.get('municipality') + '&district=' + params.get('district'))
            }
        }
    }, [])

    return (
        <SimplePage>
            <Link to={urls.buy_land.path + '?type=' + params.get('type') + '&municipality=' + params.get('municipality') + '&district=' + params.get('district')} className='back-to-map' >
                <svg width="24" height="24" viewBox="0 0 24 24" stroke="#0088CC" xmlns="http://www.w3.org/2000/svg">
                    <use href={`${icons.ArrowLeft}#arrow-narrow-left`} />
                </svg>
                К выбору участка</Link>
            <h1 className='page-title'>{title}</h1>
            {loading ?
                <div className="buy-land__container">
                    <Loader /></div>
                : <div className="buy-land__container">
                    <div className="buy-land__info">
                        <span className='buy-land__cadastral'>{mapData.selectedLandploat?.cadastral}</span>
                        <span className='buy-land__municipality'>{mapData.selectedLandploat?.municipality}</span>
                        <span className='buy-land__address'>{mapData.selectedLandploat?.micro_district}, №{mapData.selectedLandploat?.number}</span>
                        {mapData.selectedLandploat?.area && <span className='buy-land__area'>{mapData.selectedLandploat.area} м²</span>}

                        {mapData.selectedLandploat?.land_cost &&
                            <>
                                <hr />
                                <div className='buy-land__land-cost'>
                                    <span className='cost__title'>Стоимость</span>
                                    <span className='cost__dotted'></span>
                                    <span className='cost__cost'>{formatter.format(mapData.selectedLandploat.land_cost)}</span>
                                </div>
                            </>
                        }
                    </div>
                    <div className="buy-land__map">
                        <MapComponents coordinats={mapData.selectedLandploat?.coordinates} activeLandploat={mapData.selectedLandploat} />
                    </div>
                    {mapData.selectedLandploat && <BuyLandForm activeLandploat={mapData.selectedLandploat} />}

                </div>
            }
        </SimplePage >
    )
}

export default BuyLandPage