import apiConfig from "./apiConfig";
import axiosClient from './axiosClient';

class BaseModelAPI {
    constructor(modelUrl, baseUrlDinamick) {
        this.baseUrl = baseUrlDinamick ? baseUrlDinamick : apiConfig.baseUrl;
        this.baseUrl += modelUrl;
        this.defaultParams = {
            method: '',
            urlParams: '',
            id: '',
            body: null,
        };
    }

    returnResponse(resp) {
        return {
            success: !!resp,
            data: resp || null,
            message: resp ? 'Success' : 'No response received',
        };
    }



    async makeRequest(requestFn, params = {}) {

        const { method, id, urlParams, body } = { ...this.defaultParams, ...params };
        // console.log('====================================');
        // console.log('urlParams', urlParams);
        // console.log('====================================');
        try {
            const resp = await requestFn(this.baseUrl + method + id + urlParams, body);
            return this.returnResponse(resp);
        } catch (error) {
            return { success: false, data: error, message: error.message || 'Unknown error' };
        }
    }

    async options(params = {}) {
        return this.makeRequest(axiosClient.options, params);
    }

    async list(params = {}) {
        return this.makeRequest(axiosClient.get, params);
    }

    //Для передачи id передать urlParams = #id
    async getById(params = {}) {
        return this.makeRequest(axiosClient.get, params);
    }

    async create(params = {}) {
        return this.makeRequest(axiosClient.post, params);
    }

    //Для передачи id передать urlParams = #id
    async update(params = {}) {
        return this.makeRequest(axiosClient.put, params);
    }

    //Для передачи id передать urlParams = #id
    async delete(params = {}) {
        return this.makeRequest(axiosClient.delete, params);
    }
}

export default BaseModelAPI;