import React from 'react'
import Loader from '../../UI/Loader/Loader'
import { useDispatch, useSelector } from 'react-redux'
import './MobileDistrictList.scss'
import MobileDistrictCard from './MobileDistrictCard/MobileDistrictCard'
import { setActiveDistrict, setSelectedLandploat } from '../../../store/mapDataSlice'
import DistrictDropdown from './MobileDistrictCard/MapDropdown/DistrictDropdown'
import LandplotsDropdown from './MobileDistrictCard/MapDropdown/LandplotsDropdown'
import urls from '../../../urls'
import { useNavigate } from 'react-router-dom'

const MobileDistrictList = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const setDistrict = id => dispatch(setActiveDistrict(id))
    const setLandPloat = (obj) => dispatch(setSelectedLandploat(obj))
    const mapData = useSelector(state => state.map_data.mapData)
    const municipalitiesList = mapData.municipalitiesList
    const microdistrictList = mapData.microdistrictList
    const landplotsList = mapData.landplotsList

    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)

    const landplotClick = (obj) => {
        setLandPloat(obj)
        navigate(urls.buy_land_form.path + '?' + params.toString() + '&landplot=' + obj.id)
    }

    const selectDistrict = (id) => {
        window.location.search = `?type=${params.get('type')}&municipality=${mapData.isActiveMunicipality}&district=${id}`
    }

    return (
        <>
            <div className='mobile-district-list'>
                {mapData.municipalitiesLoading ?
                    <Loader /> :
                    municipalitiesList.map(mun => {
                        if (+mun.land_plots_count_for_sale > 0) {
                            if (mapData.isActiveMunicipality) {
                                if (mapData.isActiveMunicipality === mun.id) {
                                    return <MobileDistrictCard key={mun.id} municipality={mun} classes={'sticky'} />
                                }
                            } else {
                                return <MobileDistrictCard key={mun.id} municipality={mun} />
                            }
                        }
                    })
                }
            </div>
            {mapData.isActiveMunicipality ?
                microdistrictList.length > 0 ?
                    <DistrictDropdown list={microdistrictList} onClick={selectDistrict} value={mapData.isActiveDistrict} />
                    : 'Здесь пока нет микрорайонов'
                : ''}

            {mapData.isActiveDistrict ?
                landplotsList.length > 0 ?
                    <LandplotsDropdown list={landplotsList} onClick={landplotClick} />
                    : 'Нет свободных участков'
                : ''}
        </>
    )
}

export default MobileDistrictList