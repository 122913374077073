import React from 'react'

import FormPage from '../../components/FormPage/FormPage'
import LoginForm from '../../components/forms/LoginForm/LoginForm'

const LoginPage = () => {
  return (
      <FormPage>
        <LoginForm />
      </FormPage>
  )
}

export default LoginPage