import BaseModelAPI from "../BaseModelAPI";
import axiosClient from "../axiosClient";
import { API_DIST_MODEL } from "./const";

class MunicipalitiesApiRequest extends BaseModelAPI {
    constructor() {
        super('map/' + API_DIST_MODEL.url);
    }
}

export default MunicipalitiesApiRequest;
