import axios from 'axios';
import queryString from 'query-string';

import apiConfig from './apiConfig';
import urls from '../urls';

const token = JSON.parse(localStorage.getItem("token"));

const axiosClient = axios.create({
    baseURL: apiConfig.baseUrl,
    headers: {
        ...(token && { 'Authorization': `Bearer ${token}` }),
        'Content-Type': 'application/json'
    },
    paramsSerializer: params => queryString.stringify({ ...params, api_key: apiConfig.apiKey })
});

// ждем обновление localStorage
axiosClient.interceptors.request.use(async (config) => {
    const token = JSON.parse(localStorage.getItem("token"));
    if (token) {
        // вставляем значение токена в headers
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
});

axiosClient.interceptors.request.use(async (config) => config);

axiosClient.interceptors.response.use((response) => {
    if (response && response.data) {
        return response.data;
    }

    return response;
}, (error) => {
    console.log('error', error.response.status)
    if (error.response.status === 401) {
        window.location.pathname = urls.refresh.path
    }
    if (error.response.status === 405) {
        window.location.pathname = urls.logout.path
    }
    throw error;
});


const axiosOuter = axios.create({
    baseURL: apiConfig.baseUrl,
    headers: {
        'Content-Type': 'application/json'
    },
    paramsSerializer: params => queryString.stringify({ ...params, api_key: apiConfig.apiKey })
});

axiosOuter.interceptors.request.use(async (config) => config);

axiosOuter.interceptors.response.use((response) => {
    if (response && response.data) {
        return response.data;
    }

    return response;
}, (error) => {
    throw error;
});

export { axiosOuter }
export default axiosClient;