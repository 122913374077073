import BaseModelAPI from "../BaseModelAPI";
import axiosClient, {axiosOuter} from "../axiosClient";
import { API_CABINET_MODEL } from "./const";

class CabinetRequest extends BaseModelAPI {
    constructor() {
        super(API_CABINET_MODEL.url);
    }

    async sendCode(body) {
        return this.makeRequest(axiosOuter.post, {method: API_CABINET_MODEL.methods.send_code.url, body: body });
    }
    async login(body) {
        return this.makeRequest(axiosOuter.post, {method: API_CABINET_MODEL.methods.login.url, body: body });
    }
    async history(id) {
        return this.makeRequest(axiosClient.get, { id: id, method: API_CABINET_MODEL.methods.get_client_task.url });
    }
    async refresh(body) {
        return this.makeRequest(axiosClient.post, { method: API_CABINET_MODEL.methods.refresh.url, body: body });
    }
}

export default CabinetRequest;
