import React from 'react'

import './HistoryPoint.scss'

const HistoryPoint = ({ item }) => {
    return (
        <div className='history'>
            <span className='history__date'>{item.create_at}</span>
            <span className='history__text'>{item.category?.name}</span>
            <span className='history__date'>{item.cadastral}</span>
            <span className='history__status'>{item.status?.name}</span>
        </div>
    )
}

export default HistoryPoint