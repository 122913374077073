import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

import FormItem from '../../UI/fields/FormItem'
import FormPage from '../../FormPage/FormPage';

import { onEmailInput, onSnilsInput, onPassportInput } from '../../../functions/mask'

const DataForm = ({ title }) => {
    const [btnActive, setBtnActive] = useState(false)
    const navigate = useNavigate();
    const [errors, setErrors] = useState({})
    const [data, setData] = useState({})

    const [userData, setUserData] = useState([
        {
            name: 'passport',
            type: 'text',
            placeholder: '00 00 000000',
            label: 'Серия и номер паспорта',
            mask: onPassportInput
        },
        {
            name: 'address',
            type: 'text',
            label: 'Адрес регистрации',
            required: true,
        },
        {
            name: 'passport_copy',
            type: 'file',
            label: 'Копия паспорта (с пропиской)',
        },
        {
            name: 'municipality',
            type: 'select',
            label: 'Муниципальное образование',
        },
        {
            name: 'microdistrict',
            type: 'select',
            label: 'Микрорайон',
        }
    ])

    const [contractBIK, setContravctBIK] = useState([
        {
            name: 'contract_number',
            type: 'text',
            label: 'Номер договора',
            placeholder: '00 00 000000 '
        },
        {
            name: 'date',
            type: 'date',
            label: 'Дата заключения',
            placeholder: 'ДД.ММ.ГГГГ',
        },
        {
            name: 'address',
            type: 'text',
            label: 'Адрес объекта',
        },
        {
            name: 'codastr_number',
            type: 'text',
            label: 'Кадастровый номер',
        },
        {
            name: 'contract_copy',
            type: 'file',
            label: 'Копия договора',
        },
        {
            name: 'egrn',
            type: 'file',
            label: 'Выписка из ЕГРН',
        },
    ])

    const [contractFond, setContravctFond] = useState([
        {
            name: 'contract_number',
            type: 'text',
            label: 'Номер договора',
            placeholder: '00 00 000000 '
        },
        {
            name: 'date',
            type: 'date',
            label: 'Дата заключения',
            placeholder: 'ДД.ММ.ГГГГ',
        },
        {
            name: 'contract_copy',
            type: 'file',
            label: 'Копия договора',
        },
    ])

    const [contractBIKInvest, setContravctBIKInvest] = useState([
        {
            name: 'contract_number',
            type: 'text',
            label: 'Номер договора',
            placeholder: '00 00 000000 '
        },
        {
            name: 'date',
            type: 'date',
            label: 'Дата заключения',
            placeholder: 'ДД.ММ.ГГГГ',
        },
        {
            name: 'contract_copy',
            type: 'file',
            label: 'Копия договора',
        },
    ])

    const setValue = (name, value) => {
        if (value) {
            data[name] = value
        } else {
            try {
                delete data[name]
            } catch { }
        }
        setData({ ...data })
        if (Object.keys(data).length > 0) {
            setBtnActive(true)
        } else {
            setBtnActive(false)
        }
    }

    const setError = (name, error) => {
        if (error) {
            errors[name] = error
            //console.log(errors)
        } else {
            try {
                delete errors[name]
            } catch { }
        }
        setErrors({ ...errors })
    }

    const validation = () => {
        let state = {}
        for (const field of userData) {
            if (field.required) {
                if (data[field.name]) {
                    if (field.mask) {
                        field.mask(field.name, data[field.name])
                    } else {
                        return true
                    }
                } else {
                    return 'Поле обязательно для заролнения'
                }
            }
        }
        return true
    }

    const sendData = () => {
        const valid = validation()
        if (Object.keys(errors).length > 0) {

        } else {

        }
        //navigate('/')
    }

    const nope = () => {
        alert('Очень хитро, но поля заполнить все равно придется)')
    }

    return (
        <FormPage>
            <h1 className='form-card__title-24'>{title}</h1>
            <p className='form-card__description'>Шаг 2</p>

            {userData.map(item => (
                <FormItem
                    key={`userData_${item.name}`}
                    field={item}
                    mask={item.mask}
                    value={data[item.name] || ''}
                    setValue={setValue}
                    error={errors[item.name] || false}
                    setError={setError} />
            ))}

            <h2 className='form-card__title-20'>Добавить договор</h2>
            <p className='form-card__description mt-16'>Вам необходимо загрузить все действующие договоры для возможности подачи заявок и получения услуг</p>

            <h3 className='form-card__title-16 pt-12 mb-16'>Договор с БИК</h3>

            {contractBIK.map(item => (
                <FormItem
                    key={`contractBIK_${item.name}`}
                    field={item}
                    mask={item.mask}
                    value={data[item.name] || ''}
                    setValue={setValue}
                    error={errors[item.name] || false}
                    setError={setError}
                />
            ))}

            <h3 className='form-card__title-16 pt-12 mb-16'>Договор с Фонд поддержки ИЖС</h3>
            {contractFond.map(item => (
                <FormItem
                    key={`contractFond_${item.name}`}
                    field={item}
                    mask={item.mask}
                    value={data[item.name] || ''}
                    setValue={setValue}
                    error={errors[item.name] || false}
                    setError={setError} />
            ))}

            <h3 className='form-card__title-16 pt-12 mb-16'>Договор с КПК БИК-Инвест</h3>
            {contractBIKInvest.map(item => (
                <FormItem
                    key={`contractBIKInvest_${item.name}`}
                    field={item}
                    mask={item.mask}
                    value={data[item.name] || ''}
                    setValue={setValue}
                    error={errors[item.name] || false}
                    setError={setError} />
            ))}

            <span className={`form-btn _block mt-16 ${btnActive ? '' : '_disabled'}`} onClick={btnActive ? sendData : nope}>Отправить</span>
        </FormPage>
    )
}

export default DataForm