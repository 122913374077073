import React from "react"
import './Modal.scss'

function Modal({ active = false, openModal, closeModal, children, classes, styles ={} }) {
  
    // classes: _small
    return (
        <div className={active ? "modal active" : "modal"} onClick={closeModal} style={styles}>
            <div className={`modal__content ${classes ? classes : ''}`} onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </div>
    )
}

export default Modal