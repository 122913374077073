import React, { useState, useEffect } from "react"
import MySelect from "./MySelect";

function SelectItem(props) {
    //console.log(props)
    const [value, setValue] = useState('')
    // useEffect(() => {
    //     //console.log(props.field.name, props.value)
    //     if (props.value)
    //         setValue(props.value)
    //     else 
    //         setValue('')
    // }, [props.value])

    useEffect(() => {
        //console.log('value', value);
        //console.log('props.value', props.value);
        if (props.value) {
            const options = props.field.choices.filter(option => option.value === props.value.toString())
            //console.log('options', options);
            setValue(options[0]);
        } else {
            setValue('')
        }
    }, [props.value]);


    const handleChange = selectedOption => {
        if (props.disabled)
        {}
        else
            {props.setValue(props.field.name, selectedOption.value)}
    }

    return (
        <div className={"form__item " + props.classes}>
            <span className="error-message">{props.error ? props.error : ''}</span>
            <MySelect classNamePrefix="select-item"
                className={props.readOnly ? '_read-only' : ''}
                onChange={handleChange}
                noOptionsMessage={() => 'Нет результатов'}
                value={value}
                options={props.field.choices}
                isMulti={props.field.multiple && true}
                name={props.field.name}
                placeholder={props.field.placeholder || ''}
                isSearchable={true}
                required={props.required}
                isDisabled={props.field.disabled}
            />
            <label htmlFor={props.field.name}>{props.field.label}{props.field.required || props.required ? <span>*</span> : ''}</label>
        </div>
    )
}

export default SelectItem