import BaseModelAPI from "../BaseModelAPI";
import axiosClient from "../axiosClient";
import { API_CABINET_MODEL } from "./const";

class ContragentRequest extends BaseModelAPI {
    constructor() {
        super(API_CABINET_MODEL.url);
    }

    async getById(id) {
        return this.makeRequest(axiosClient.get, { id: id, method: API_CABINET_MODEL.methods.get_contragent.url });
    }
    async update(body) {
        return this.makeRequest(axiosClient.post, {method: API_CABINET_MODEL.methods.update_contragent.url, body: body });
    }
    async create(body) {
        return this.makeRequest(axiosClient.post, {method: API_CABINET_MODEL.methods.create_contragent.url, body: body });
    }
}

export default ContragentRequest;
