import jwt_decode from "jwt-decode";

const userGroups = () => localStorage.getItem("groups") ? JSON.parse(localStorage.getItem("groups")) : [] // Document_flow, Users, Admin

const userPermissions = () => localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [] // ['add_somemodel', 'view_somemodel', 'change_somemodel', 'delete_somemodel', other models...]

const userHasGroup = (group) => userGroups().indexOf(group) !== -1

const userHasPermission = (permission) => {

    const groups = localStorage.getItem("groups") ? JSON.parse(localStorage.getItem("groups")) : []
    const permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : []
    if (groups && permissions)
        return groups.indexOf('Admin') !== -1 || permissions.indexOf(permission.toLowerCase()) !== -1
    else if (groups) {
        return groups.indexOf('Admin') !== -1
    }
    else if (permissions) {
        return permissions.indexOf(permission.toLowerCase()) !== -1
    }
    return false
}

const organisationCheck = (data) => {
    if (data.organisation.length === 1)
        return data.organisation[0].name
    else if (data.organisation.length === 2)
        return data.organisation[0].name + ', ' + data.organisation[1].name
    return ''
}

const postCheck = (data) => {
    if (data.post_bik && !data.post_fond)
        return data.post_bik
    else if (!data.post_bik && data.post_fond)
        return data.post_fond
    else if (data.post_bik && data.post_fond)
        return data.post_bik + ', ' + data.post_fond
    return ''
}

const getDomain = () => ('https://collback.bik31.ru') //'http://192.168.32.50:8001' '8011' 'https://collback.bik31.ru'

function setUserData(data, addUserData) {
    localStorage.setItem("user_id", data.id)
    addUserData(['user_id', data.id])

    const newGroups = []
    const permissions = []
    for (const group of data.groups) {
        try {
            for (const permission of group.permissions) {
                permissions.push(permission.codename)
            }
        } catch (error) {
            console.log('permissions error', group, error)
        }
        newGroups.push(group.name)
    }

    localStorage.setItem("groups", JSON.stringify(newGroups))
    addUserData(['groups', JSON.stringify(newGroups)])
    localStorage.setItem("permissions", JSON.stringify(permissions))
    addUserData(['permissions', JSON.stringify(permissions)])

    const post = postCheck(data)
    localStorage.setItem("post", post)
    addUserData(['post', post])

    const organisation = organisationCheck(data)
    localStorage.setItem("organisation", organisation)
    addUserData(['organisation', organisation])

    const user_name = data.last_name ? data.last_name + ' ' + data.first_name[0] + '.' + data.patronymic[0] + '.' : data.username
    localStorage.setItem("user_name", user_name)
    addUserData(['user_name', user_name])
}

const getUserId = () => {
    return localStorage.getItem('user_id')
}

const checkNotifications = (id, notification_list) => {
    const notifies = notification_list.filter((item) => item.instance_id === id)
    if (notifies.length === 0)
        return false
    return true
}

const getItems = (url, method, setData) => {
    //console.log(' ------------- actual token', localStorage.getItem("token"))
    fetch(url, {
        method: method,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'application/json'
        }
    })
        .then(response => {
            // console.log('getItems', response)
            if (!response.ok) {
                if (response.status === 500) {
                    throw Error('Ошибка сервера. Попробуйте позже.')
                } else if (response.status === 401) {
                    window.location.replace('/refresh/')
                } else {
                    throw Error(response.statusText)
                }
            }
            if (response.status === 204) {
                throw Error('Ошибка сервера (нет контента). Попробуйте позже.')
            }
            return response.json()
        })
        .then(data => setData(data))
        .catch(err => console.error(err))
}

const createOrUpdate = (e, method, setLoading, setAlert, setErrors, navigateTo, fields) => {
    e.preventDefault()
    const form = e.currentTarget
        , url = form.action
        , formData = new FormData(form) // form.querySelectorAll(input)
        , formJson = {}
        , nested_fields = ['saleaction', 'other_doc', 'act_files', 'other_files', 'doc_payment', 'form_files', 'level_agreements']

    //console.log('formJson', formJson)

    for (let [name, value] of formData) {
        if (name === 'password' && value === '') {
            formData.delete(name)
            break;
        }
        if (fields[name]) {
            // console.log(name, fields[name])

            if (nested_fields.includes(name)) {
                formJson[name] = JSON.parse(value)
            } else if (fields[name].type === 'nested' || fields[name].multiple === true) {
                if (formJson[name]) {
                    const arr = formJson[name]
                    arr.push(value)
                    formJson[name] = arr
                } else {
                    const arr = []
                    arr.push(value)
                    formJson[name] = arr
                }
            } else if (fields[name].type === 'boolean') {
                if (value === 'on') {
                    formJson[name] = true
                }
            } else {
                formJson[name] = value
            }
        }
    }

    for (const [key, value] of Object.entries(fields)) {
        if (!formJson[key] && value.type === "boolean") {
            formJson[key] = false
        }
    }

    // console.log('---- Form Json ----', formJson)

    setLoading(true)
    fetch(url, {

        method: method,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'application/json'
        },
        credentials: "same-origin",
        body: JSON.stringify(formJson)
    })
        .then(response => {
            if (response.status === 200 || response.status === 201) {
                return [response.json(), 'success']
            } else if (response.status === 400) {
                return [response.json(), 'error']
            } else if (response.status === 401) {
                window.location.replace('/refresh/')
            } else {
                setTimeout(() => {
                    setLoading(false)
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                    setAlert({ title: `Ошибка, статус: ${response.status}`, text: response.statusText, type: 'error' })
                }, 1000);
            }
            return response
        })
        .then(([data, status]) => {
            if (status === 'success') {
                setTimeout(() => {
                    //console.log(data)
                    data.then(d => navigateTo(d.id))
                    setLoading(false)
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                    setAlert({ title: 'Данные сохранены', type: 'success' })
                }, 1000)
                // setTimeout(() => {
                //     navigate(-1)
                // }, 3000)
            } else if (status === 'error') {
                setTimeout(() => {
                    setLoading(false)
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                    setErrors(data)
                    setAlert({ title: `Ошибка`, text: 'Неверно заполнены поля', type: 'error' })
                }, 1000);
            } else {
                console.log(data)
            }
        })
}

const createFieldsObject = (data) => {
    const obj = {}
    for (const key in data) {
        const field = {
            name: key,
            label: data[key].label,
            type: data[key].type,
            value: '',
            required: data[key].required,
            read_only: data[key].read_only,
            placeholder: '',

            error: ''
        }


        if (data[key].type === "field" || data[key].type === "string" || data[key].type === "integer")
            field.type = "text"
        if (data[key].type === "file upload")
            field.type = "file"
        if (data[key].type === "field" && data[key].choices) {
            field.type = "select"
            field.children = data[key].choices
            for (const c of field.children) {
                c.label = c.display_name
            }
        }
        if (key === "level_agreements")
            field.choices = data[key].child.children.rel_users.choices
        else {
            if (data[key].type === "choice") {
                field.type = "radio"
                field.choices = data[key].choices
                for (const c of field.choices) {
                    c.label = c.display_name
                }
            }
            if (data[key].child) {
                if (data[key].child.children) {
                    field.choices = data[key].child.children[Object.keys(data[key].child.children)[0]].choices
                    if (field.choices) {
                        field.type = 'nested'
                        for (const c of field.choices) {
                            c.label = c.display_name
                        }
                    } else {
                        field.type = 'nested'
                    }
                }
            }
        }


        if (data[key].type === "integer")
            field.type = "number"
        if (data[key].type === "boolean")
            field.type = "boolean"
        if (data[key].type === "float")
            field.type = "text"

        if (key === 'description')
            field.type = 'textarea'
        if (key === 'resolution')
            field.type = 'textarea'
        if (data[key].multiple)
            field.multiple = true


        const newField = new Object()
        newField[key] = field
        Object.assign(obj, newField)
    }
    return obj
}

const getFileName = (name) => {
    const decodedUrl = decodeURIComponent(name)
        , arr_path = decodedUrl.split('/')
        , file_name = arr_path[arr_path.length - 1]
        , file_name_arr = file_name.split('_')
        , new_name = file_name.replace('_' + file_name_arr[file_name_arr.length - 1].split('.')[0], "")
    return new_name
}

function hasDoubleNestedArray(arr) {
    for (let i = 0; i < arr.length; i++) {
        if (Array.isArray(arr[i])) {
            for (let j = 0; j < arr[i].length; j++) {
                if (Array.isArray(arr[i][j])) {
                    return true; // Если найден двойной вложенный массив, вернуть true
                }
            }
        }
    }
    return false; // Если двойной вложенный массив не найден, вернуть false
}
//проверка на массив массивов
function hasNestedArray(arr) {
    for (let i = 0; i < arr.length; i++) {
        if (Array.isArray(arr[i])) {
            return true; // Если элемент массив, вернуть true
        }
    }
    return false; // Если ни один элемент не является массивом, вернуть false
}

function fieldToArray(fields) {
    if (!fields) {
        return [];
    }

    const fieldEntries = Object.entries(fields);

    if (fieldEntries.length === 0) {
        return [];
    }

    const resultArray = fieldEntries.map(([key, value]) => ({ key, value }));
    return resultArray;
}

const getMaxDepth = arr =>
    Array.isArray(arr)
        ? 1 + Math.max(0, ...arr.map(getMaxDepth))
        : 0;


export { getMaxDepth, fieldToArray, hasDoubleNestedArray, hasNestedArray, getItems, getDomain, setUserData, organisationCheck, postCheck, checkNotifications, createFieldsObject, userGroups, userHasGroup, userHasPermission, getFileName, createOrUpdate }