import React, { useEffect } from 'react'
import { cleanUserData, setUserDataItem } from '../../store/userDataSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import urls from '../../urls'
import CabinetRequest from '../../api/cabinet/Cabinet'

const Refresh = () => {
    const dispatch = useDispatch()
        , navigate = useNavigate()
        , userData = useSelector(state => state.user_data.userData)
        , addUserData = (name, value) => dispatch(setUserDataItem([name, value]))
        , cabinet = new CabinetRequest()

    useEffect(() => {
        cabinet.refresh({ refresh: userData.refresh })
            .then(response => {
                if (response.success && response.data.refresh && response.data.access) {
                    addUserData('refresh', response.data.refresh)
                    addUserData('token', response.data.access)
                    navigate(urls.cabinet_page.path)
                } else {
                    dispatch(cleanUserData())
                    navigate(urls.login.path)
                }
            })
    }, [])

    return (
        <div>Refresh</div>
    )
}

export default Refresh