import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './styles.scss';
import MapComponents from './MapComponents/MapComponents';
import MicroDistrictApiRequest from '../../api/microDistrict/MicroDistrict';
import MunicipalitiesApiRequest from '../../api/municipality/Municipalities';
import DistrictList from './DistrictList/DistrictList';
import {
    setMicrodistrictList,
    setMunicipalitiesList,
    setLandplotList,
    setMicrodistrictsLoading,
    setMunicipalitiesLoading,
    setLandplotLoading,
    setCoordinatesItem,
    setError
} from '../../store/mapDataSlice';
import Loader from '../UI/Loader/Loader';
import { useWindowSize } from '../../functions/useWindowSize';
import MobileDistrictList from './MobileDistrictList/MobileDistrictList';
import { useNavigate } from 'react-router';
import urls from '../../urls';

const MapPage = () => {
    const [width, height] = useWindowSize();
    const navigate = useNavigate()

    const micropdistPlotInstance = new MicroDistrictApiRequest();
    const municipalitiesPlotInstance = new MunicipalitiesApiRequest();
    const mapData = useSelector(state => state.map_data.mapData)
    const dispatch = useDispatch()

    const setMunicipalities = array => dispatch(setMunicipalitiesList(array))
    const setMicrodistricts = array => dispatch(setMicrodistrictList(array))
    const setMapError = boolean => dispatch(setError(boolean))

    const setLandplots = array => dispatch(setLandplotList(array))
    const isActiveMunicipality = mapData.isActiveMunicipality
    const isActiveDistrict = mapData.isActiveDistrict

    const setCoordinates = array => dispatch(setCoordinatesItem(array))

    const setMunLoading = status => dispatch(setMunicipalitiesLoading(status))
    const setDistrLoading = status => dispatch(setMicrodistrictsLoading(status))
    const setLandLoading = status => dispatch(setLandplotLoading(status))

    const [mapLoading, setMapLoading] = useState(true);
    const [status, setStatus] = useState(true)

    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)


    // все муниципалитеты
    const fetchMunicipalities = () => {
        setMapLoading(true)
        setMunLoading(true)
        municipalitiesPlotInstance.list({urlParams: `?type=${params.get('type')}`})
            .then((resp) => {
                if (resp.success) {
                    setMapError(false)
                    //console.log('resp', resp);
                    if (!isActiveMunicipality) {
                        setCoordinates([])
                        let new_coords = []
                        resp.data.data.map(item => {
                            //console.log('m item ===========', item);
                            new_coords.push(item.coordinates)
                        })
                        setCoordinates([...new_coords])
                    }
                    //setLandplots([])
                    //setMicrodistricts([])
                    setMunicipalities(resp.data.data)
                } else {
                    setMapError(true)
                    setStatus(resp.message)
                }
                setMapLoading(false)
                setMunLoading(false)
            });
    };

    // микрорайоны муниципалитета
    const fetchMunicipalityItem = () => {
        setMapLoading(true)
        setMunLoading(true)
        setDistrLoading(true)
        //console.log('isActiveMunicipality', isActiveMunicipality);
        micropdistPlotInstance.mapApi(isActiveMunicipality, `/?type=${params.get('type')}`).then((resp) => {
            if (resp.success) {
                setMapError(false)
                setMicrodistricts(resp.data.data)
                //setLandplots([])
                if (!isActiveDistrict) {
                    setCoordinates([])
                    let new_coords = []
                    resp.data.data.map(item => {
                        new_coords.push(item.coordinates)
                    })
                    setCoordinates([...new_coords])
                }
                // resp.data.data.map(item => setCoordinates((prevContent) => [...prevContent, item.coordinates]))

            } else {
                setMapError(true)
            }
            setMapLoading(false)
            setMunLoading(false)
            setDistrLoading(false)
        })
    };

    // участки
    const fetchLandplots = () => {
        setMapLoading(true)
        setLandLoading(true)
        //console.log('isActiveDistrict', isActiveDistrict);
        micropdistPlotInstance.landplotsApi(isActiveDistrict, `/?type=${params.get('type')}`)
            .then((resp) => {
                if (resp.success) {
                    setMapError(false)
                    //console.log('resp', resp)
                    const reversedCoordinates = resp.data.data.coordinates?.map((item) => item);
                    setLandplots([])
                    setCoordinates([])
                    setLandplots([...resp.data.data?.land_plots])
                    setCoordinates([...reversedCoordinates])
                } else {
                    setMapError(true)
                }
                setMapLoading(false)
                setMunLoading(false)
                setDistrLoading(false)
                setLandLoading(false)
            })
    };

    useEffect(() => {
        if (!params.get('type')) {
            window.location.href = urls.buy_land.path + urls.buy_land.query_string
            //navigate(urls.buy_land.path + urls.buy_land.query_string)
        }
        if (isActiveDistrict) {
            //console.log('!!!!!!!!!!!!!!fetchLandplots');
            fetchLandplots()
        }
        if (isActiveMunicipality) {
            //console.log('!!!!!!!!!!!!!!fetc hMunicipality Item');
            fetchMunicipalityItem()
        }
        if (!mapData.municipalitiesList.length > 0) {
            //console.log('!!!!!!!!!!!!!!fetch Municipalities');
            fetchMunicipalities();
        }
    }, []); //[isActiveMunicipality, isActiveDistrict]


    return (
        <>
            {/* <span>Window size: {width} x {height}</span> */}
            {mapData.error ? <>
                <div></div>
                <p className='map-error' >Возникла непредвиденная ошибка. Попробуйте
                    <span onClick={() => window.location.reload()} className='cabinet-btn mx-8'>перезагрузить страницу</span>
                    или зайти позже.
                </p></> :
                <div className='map-container'>
                    {width > 1024 ? <DistrictList /> : <MobileDistrictList />}
                    {mapLoading ? <Loader /> : (
                        <>
                            <div className='mapPageContainer'>
                                <MapComponents />
                            </div>
                        </>
                    )}
                </div>
            }
            <div className='map-info'>
                <p className='map-info__text _bold'>На данный момент система работает в режиме тестирования: могут возникать сбои в бронировании
                    и существует возможность бронирования одного и того же участка несколько раз разными клиентам.
                    Мы оставляем за собой право закреплять бронирование за первым человеком, оставившим заявку.
                    Приносим извинения за неудобства.</p>

                <p className='map-info__text'>Предварительное бронирование не является публичной офертой, носит исключительно ознакомительный
                    характер с предлагаемыми земельными участками для продажи. Предварительное бронирование
                    работает только на один забронированный участок, последующие бронирования участков не принимаются.
                    Цены на земельные участки не являются окончательными и могут быть изменены продавцом
                    в одностороннем порядке до заключения договора купли-продажи.</p>
            </div>
        </>
    );
};

export default MapPage;
