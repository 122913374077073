import React, { useState } from 'react'

import './MapDropdown.scss'
import arrow from '../../../../../assets/select-arrow.svg'

const LandplotsDropdown = ({ list, onClick }) => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div className='map-dropdown__wrap'>
            <div className={`map-dropdown ${isOpen ? '_active' : ''}`} onClick={() => setIsOpen(!isOpen)}>Участки<img src={arrow} alt="" /></div>
            
            {isOpen ?
                <div className='map-dropdown__drop'>
                    {list.map(item => {
                        return <div className='landplot' onClick={() => {
                            setIsOpen(false)
                            onClick(item)
                        }}><span className='landplot__name'>{item.cadastral}</span>
                        </div>
                    })}
                </div> :
                ''}
        </div>
    )
}

export default LandplotsDropdown