export const API_DIST_MODEL = {
    entity: 'map',
    url: 'map/',
    methods: {
        map: {
            url: 'microdistricts/'
        },
        landplots: {
            url: 'landplots/'
        },
        landplot_options: {
            url: 'landplot-options/'
        },
        // map_microdistrict: {
        //     url: '/map/microdistricts/'
        // },
        import_municipalities: {
            url: 'import_municipalities/'
        },
        import_microdistrict: {
            url: 'import_microdistrict/'
        },
        fix_name_microdistrict: {
            url: 'fix_name_microdistrict/'
        },
    },
}