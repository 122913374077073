export default (str) => {
    let parsed_date
    try {
        parsed_date = new Date(str)
    } catch {
        console.log('--- Неподдерживаемый формат даты ---', str)
        return ''
    }
    const date_now = new Date()

    const d = parsed_date.getDate().toString().padStart(2, "0");
    const mon = (parsed_date.getMonth() + 1).toString().padStart(2, "0"); // Добавляем ведущий ноль, если месяц меньше 10
    const y = parsed_date.getFullYear();
    let h
    let m

    try {
        h = parsed_date.getHours().toString().padStart(2, "0")
        m = parsed_date.getMinutes().toString().padStart(2, "0")
    } catch {
        console.log('--- В дате нет данных о времени ---', str)
        return `${d}.${mon}.${y}`;
    }

    return `${d}.${mon}.${y} ${h}:${m}`;
}