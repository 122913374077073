import { createSlice } from '@reduxjs/toolkit'
import { getMaxDepth } from '../functions/functions';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

const mapData = {
    // isActiveMunicipality: (sessionStorage.getItem("isActiveMunicipality") ? JSON.parse(sessionStorage.getItem("isActiveMunicipality")) : null),
    // isActiveDistrict: (sessionStorage.getItem("isActiveDistrict") ? JSON.parse(sessionStorage.getItem("isActiveDistrict")) : null),
    // municipalitiesList: (sessionStorage.getItem("municipalitiesList") ? JSON.parse(sessionStorage.getItem("municipalitiesList")) : []),
    // microdistrictList: (sessionStorage.getItem("microdistrictList") ? JSON.parse(sessionStorage.getItem("microdistrictList")) : []),
    // landplotsList: sessionStorage.getItem("landplotsList") ? JSON.parse(sessionStorage.getItem("landplotsList")) : [],
    municipalitiesLoading: true,
    microdistrictsLoading: true,
    landplotsLoading: true,
    // selectedLandploat: (sessionStorage.getItem("selectedLandploat") ? JSON.parse(sessionStorage.getItem("selectedLandploat")) : null),
    // coordinatesItem: (sessionStorage.getItem("coordinatesItem") ? JSON.parse(sessionStorage.getItem("coordinatesItem")) : []),



    isActiveMunicipality: urlParams.has('municipality') ? +urlParams.get('municipality') : null,
    isActiveDistrict: urlParams.has('district') ? +urlParams.get('district') : null,
    municipalitiesList: [],
    microdistrictList: [],
    landplotsList: [],
    selectedLandploat: urlParams.has('landploat') ? +urlParams.get('landploat') : null,
    coordinatesItem: [],
    coordinatesMunicipalityItem: [],
    coordinatesDistrictItem: [],
    error: false
}

export const mapDataSlice = createSlice({
    name: 'map_data',
    initialState: {
        mapData
    },
    reducers: {
        setActiveMunicipality(state, action) {
            state.mapData.isActiveMunicipality = action.payload ?? null
            //sessionStorage.setItem('isActiveMunicipality', JSON.stringify(action.payload ?? null))
        },
        setActiveDistrict(state, action) {
            state.mapData.isActiveDistrict = action.payload ?? null
            //sessionStorage.setItem('isActiveDistrict', JSON.stringify(action.payload ?? null))
        },
        setMunicipalitiesList(state, action) {
            state.mapData.municipalitiesList = action.payload ?? []
            //sessionStorage.setItem('municipalitiesList', JSON.stringify(action.payload ?? []))
        },
        setMicrodistrictList(state, action) {
            state.mapData.microdistrictList = action.payload ?? []
            //sessionStorage.setItem('microdistrictList', JSON.stringify(action.payload ?? []))
        },
        setLandplotList(state, action) {
            if (action.payload) {
                const landplots = action.payload

                for (const landplot of landplots) {
                    if (getMaxDepth(landplot.coordinates) < 3) {
                        landplot.coordinates = [landplot.coordinates]
                    }
                }
                state.mapData.landplotsList = landplots ?? []


                //sessionStorage.setItem('landplotsList', JSON.stringify(action.payload ?? []))
            }
        },
        setMunicipalitiesLoading(state, action) {
            state.mapData.municipalitiesLoading = action.payload
        },
        setMicrodistrictsLoading(state, action) {
            state.mapData.microdistrictsLoading = action.payload
        },
        setLandplotLoading(state, action) {
            state.mapData.landplotsLoading = action.payload
        },
        setSelectedLandploat(state, action) {
            state.mapData.selectedLandploat = action.payload ?? null
            //sessionStorage.setItem('selectedLandploat', JSON.stringify(action.payload ?? null))
        },
        setCoordinatesItem(state, action) {
            state.mapData.coordinatesItem = action.payload ?? []
            //sessionStorage.setItem('coordinatesItem', JSON.stringify(action.payload ?? []))
        },
        setCoordinatesMunicipalityItem(state, action) {
            state.mapData.coordinatesMunicipalityItem = action.payload ?? []
            //sessionStorage.setItem('coordinatesItem', JSON.stringify(action.payload ?? []))
        },
        setCoordinatesDistrictItem(state, action) {
            state.mapData.coordinatesDistrictItem = action.payload ?? []
            //sessionStorage.setItem('coordinatesItem', JSON.stringify(action.payload ?? []))
        },
        setError(state, action) {
            state.mapData.error = action.payload
        }
    }
})

export const {
    setActiveMunicipality,
    setActiveDistrict,
    setMunicipalitiesList,
    setMicrodistrictList,
    setLandplotList,
    setMunicipalitiesLoading,
    setMicrodistrictsLoading,
    setLandplotLoading,
    setSelectedLandploat,
    setCoordinatesItem,
    setCoordinatesMunicipalityItem,
    setCoordinatesDistrictItem,
    setError } = mapDataSlice.actions
export default mapDataSlice.reducer