import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './CabinetInfo.scss';
import urls from '../../urls';

const CabinetInfo = ({ contragent, status }) => {
    const navigate = useNavigate()

    return (
        <div className='cabinet-info'>
            <div className='user-info'>
                <div className='user-info__header'>
                    <div>
                        <h2 className='user-info__title'>Кабинет застройщика</h2>
                        <span className='user-info__name mt-4'>{contragent.fio}</span>
                    </div>
                    <button className='cabinet-btn' onClick={() => navigate(urls.user_edit.path)}>Изменить данные</button>
                </div>
                {Object.keys(contragent).length === 0 ? status : <>
                    {contragent.passport &&
                        <>
                            <span className='user-info__sub-title mt-16'>Серия и номер паспорта</span>
                            <span className='user-info__text'>{contragent.passport}</span>
                        </>
                    }
                    {contragent.fakt_address &&
                        <>
                            <span className='user-info__sub-title mt-8'>Адрес регистрации</span>
                            <span className='user-info__text'>{contragent.fakt_address}</span>
                        </>
                    }
                </>}
            </div>
            <div></div>
            {/* <div className='contracts'>
                <h2 className='contracts__title'>Действующие договоры</h2>

                <span className='contracts__sub-title mt-12'>Раздел в разработке</span> */}

                {/* <span className='contracts__sub-title mt-12'>Договор с АО «БИК»</span>
                <span className='contracts__text'>№30 32 123986 от 24.04.2022</span>

                <span className='contracts__sub-title mt-12'>Договор с АО «БИК»</span>
                <span className='contracts__text'>№30 32 123986 от 24.04.2022</span>

                <span className='contracts__sub-title mt-12'>Договор с АО «БИК»</span>
                <span className='contracts__text'>№30 32 123986 от 24.04.2022</span>

                <span className='cabinet-btn mt-12'>Добавить догвор</span> */}
            {/* </div> */}
        </div>
    )
}

export default CabinetInfo