import React, { useEffect, useState } from "react"

function TextItem(props) {
    const [value, setValue] = useState(props.value)
    useEffect(() => {
        //console.log(props.field.name, props.value)
        if (props.value)
            setValue(props.value)
        else 
            setValue('')
    }, [props.value])


    return (
        <div className={"form__item " + props.classes}>
            <span className="error-message">{(props.fieldDirty && props.error) && props.error}</span>
            <input type={props.type ? props.type : props.field.type || 'text'}
                id={props.field.id}
                name={props.field.name}
                value={value}
                onChange={props.handler}
                onBlur={props.blurHandle}
                className={props.fieldDirty && props.error !== '' ? "error" : ""}
                placeholder={props.field.placeholder}
                required={props.required}
                min={props.type === 'number' ? 0 : ''}
                readOnly={props.readOnly} />
            <label htmlFor={props.field.name}>{props.field.label}{props.field.required || props.required ? <span>*</span> : ''}</label>
        </div>
    )
}

export default TextItem