import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import apiConfig from '../../../../api/apiConfig'
import './DistrictCard.scss'
import { setActiveMunicipality, setActiveDistrict, setSelectedLandploat, setMicrodistrictList } from '../../../../store/mapDataSlice'
import { useNavigate } from 'react-router-dom'
import urls from '../../../../urls'
import StringLoader from '../../../UI/StringLoader/StringLoader'
import arrow from '../../../../assets/arrow-narrow-left.svg'

const DistrictCard = ({ municipality, classes = '' }) => {
    const mapData = useSelector(state => state.map_data.mapData)
    const dispatch = useDispatch()
    //const setMunicipality = id => dispatch(setActiveMunicipality(id))
    //const setDistrict = id => dispatch(setActiveDistrict(id))
    const setLandPloat = (obj) => dispatch(setSelectedLandploat(obj))
    //const setDistrictList = arr => dispatch(setMicrodistrictList(arr))

    const navigate = useNavigate();
    const microdistrictList = mapData.microdistrictList
    const landplotsList = mapData.landplotsList

    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)

    const selectDistrict = (id) => {
        window.location.search = `?type=${params.get('type')}&municipality=${municipality.id}&district=${id}`
    }

    return (
        <>
            <div className={`district-card ${classes}`} onClick={() => {
                if (mapData.isActiveMunicipality) {
                    // setDistrict(null)
                    // setMunicipality(null)
                    // setDistrictList([])
                    window.location.search = `?type=${params.get('type')}`
                }
                else {
                    window.location.search = `?type=${params.get('type')}&municipality=${municipality.id}`
                    //setMunicipality(municipality.id)
                }
            }}>
                <div className="district-card__wrap">
                    <div className='district-card__img-wrap'>
                        {municipality.file &&
                            <img src={`${apiConfig.baseUrlMedia}${municipality.file}`} alt="" className='district-card__img' />
                        }
                    </div>
                    <div className='district-card__body'>
                        <span className='district-card__title'>{municipality.name}</span>
                        {mapData.isActiveMunicipality ?
                            <span className='district-card__back'>
                                <img src={arrow} alt="" className='district-card__back-arrow' />
                                Вернуться назад</span> :
                            <span className='district-card__subtitle'>Доступно: {municipality.land_plots_count_for_sale}</span>
                        }
                    </div>
                </div>
            </div>
            {microdistrictList?.length > 0 && municipality.id === mapData.isActiveMunicipality ?
                microdistrictList.map((microdist, idx) => (
                    <div key={microdist.id} className='microdistrict-list'>
                        <div className={`microdistrict${microdist.id === mapData.isActiveDistrict ? ' active' : ''}`} onClick={() => selectDistrict(microdist.id)}>
                            <div className='microdistrict__title'>
                                <span className='microdistrict__name'>{microdist.name}</span>
                                <span className='microdistrict__count'>{microdist.land_plots_count_for_sale > 0 ? microdist.land_plots_count_for_sale : ''}</span>
                            </div>
                        </div>
                        {mapData.landplotsLoading && microdist.id === mapData.isActiveDistrict ?
                            <div className={`landplot`}><StringLoader /></div> :
                            landplotsList?.length > 0 && microdist.id === mapData.isActiveDistrict ?
                                landplotsList.map((landplot, index) => (
                                    <div key={index}
                                        onClick={() => { setLandPloat(landplot); navigate(urls.buy_land_form.path + '?' + params.toString() + '&landplot=' + landplot.id) }}
                                    // onMouseOver={() => setLandPloat(landplot)}
                                    >
                                        <div className={`landplot${false ? ' active' : ''}`} onClick={() => { }}>
                                            <span className='landplot__name'>{landplot.cadastral}</span>
                                            <span className='landplot__count'>{landplot.area ? <>{landplot.area} м²</> : ''}</span>
                                        </div>
                                        {index === (landplotsList.length - 1) && idx < (microdistrictList.length - 1) && <hr className='district-card__hr' />}
                                    </div>
                                )) : <></>}
                    </div>
                )) : <></>}
        </>
    )
}

export default DistrictCard