import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom"

import { cleanUserData } from '../../store/userDataSlice';
import urls from '../../urls';

const LogoutPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(cleanUserData())
    window.location.pathname = urls.cabinet_page.path
})
  return (
    <></>
  )
}

export default LogoutPage