import React, { useState, useEffect, useRef } from 'react'
import { DayPicker } from 'react-day-picker';
import { ru } from 'date-fns/locale';

import { onDateTimeInput } from "../../../functions/mask"
import getFormattedDate from '../../../functions/getFormattedDate';
import getFormattedDateTime from '../../../functions/getFormattedDateTime';
import { dateValidate } from '../../../functions/mask'

const DateItem = ({ field, type, error, value, required, blurHandle, fieldDirty, readOnly, setValue, setError, formErrors, setFormErrors, classes }) => {
    const [selected, setSelected] = useState()
    const [datePicker, setDatePicker] = useState(false)
    const input = useRef()
    const picker = useRef()

    // console.log(field.name, value)

    const selectDate = (date) => {
        setSelected(date)
        let date_str
        if (type === 'datetime') {
            date_str = getFormattedDateTime(date)
        } else {
            date_str = getFormattedDate(date)
        }
        setValue(date_str)
        setError(!dateValidate(date_str))
        setDatePicker(false)
    }

    useEffect(() => {
        document.addEventListener('click', (e) => {
            const target = e.target
            const its_datePicker = target == picker.current || picker.current?.contains(target);
            const its_input = target == input.current
            if (!its_datePicker && !its_input) {
                setDatePicker(false)
            }
        })
    }, [])

    return (
        <div className={`form__item ${classes}`}>
            <span className="error-message">{(fieldDirty && error) && error}</span>
            <div className={`date-widget${datePicker ? '' : ' d-none'}`} ref={picker}>
                <DayPicker
                    mode="single"
                    selected={selected}
                    onSelect={selectDate}
                    showOutsideDays
                    locale={ru}
                />
            </div>
            <input type="text" id={field.id}
                ref={input}
                value={value ? value : ''}
                onChange={e => onDateTimeInput(e, setValue, setError, formErrors, setFormErrors)}
                onClick={!readOnly ? () => setDatePicker(!datePicker) : () => { }}
                onBlur={blurHandle}
                autoComplete="off"
                className={fieldDirty && error ? `date${type === '-datetime' ? 'time' : ''}-mask error` : `date${type === '-datetime' ? 'time' : ''}-mask`}
                name={field.name}
                placeholder={field.placeholder}
                required={required}
                readOnly={readOnly} />
            <label htmlFor={field.name}>{field.label}{required ? <span>*</span> : ''}</label>
        </div >
    )
}

export default DateItem