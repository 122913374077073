import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import "./Header.scss"
import Logo from '../../assets/logo.svg'
import urls from '../../urls'

const Header = () => {
  const userData = useSelector(state => state.user_data.userData)
  const location = useLocation()

  return (
    <div className='container'>
      <div className="header__top">
        <a href="/" className="header__logo-link">
          <img src={Logo} className="header__logo-img" />
          <span className="header__logo-text">Белгородская <br />ипотечная корпорация</span>
        </a>
        <div className="header__info">
          <a className="header__phone" href="tel:8 800 301 29 31">8 800 301 29 31</a>
          <div className="header__social-list">
            <a href="https://t.me/ao_bik31" className="header__social _telegram"></a>
            <a href="https://ok.ru/group/70000000091730" className="header__social _odnoklassniki"></a>
            <a href="https://vk.com/bik_31" className="header__social _vk"></a>
          </div>
          {userData.token ?
            <Link className="header__logout" to={urls.logout.path}>Выход</Link> :
            location.pathname !== urls.cabinet_page.path && location.pathname !== urls.login.path && location.pathname !== urls.login_code.path && location.pathname !== urls.register.path ?
              <Link className="header__login" to={urls.login.path}>Войти</Link> : ''
          }
        </div>
      </div>
      <div className='header__menu'>
        {/* {location.pathname === urls.cabinet_page.path || location.pathname === urls.login.path || location.pathname === urls.login_code.path || location.pathname === urls.register.path ?
          <a href={urls.site_bik.path} className='go-back' target='_blank'>Вернуться на сайт БИК</a> :
          <a href={urls.cabinet_page.path} className='go-back'>Вернуться на главную</a>
        } */}
        <a href={urls.site_bik.path} className='go-back' target='_blank'>Вернуться на сайт БИК</a>

        {/* <div className='menu-container'>
          <a href={urls.sell_land.path} className='menu-item' target='_blank'>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className='menu-item__icon'>
              <use href={`${urls.sell_land.icon}#item`} />
            </svg>
            {urls.sell_land.title}</a>
          <a href={urls.obrashenie_k_gendirektoru.path} className='menu-item' target='_blank'>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='menu-item__icon'>
              <use href={`${urls.obrashenie_k_gendirektoru.icon}#item`} />
            </svg>
            Обращение</a>
        </div> */}
      </div>
    </div>
  )
}

export default Header