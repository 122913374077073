import React from 'react'

import FormPage from '../../components/FormPage/FormPage'
import LoginCodeForm from '../../components/forms/LoginCodeForm/LoginCodeForm'

const LoginCodePage = () => {
  return (
      <FormPage>
        <LoginCodeForm />
      </FormPage>
  )
}

export default LoginCodePage