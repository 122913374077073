import BaseModelAPI from "../BaseModelAPI";
import axiosClient from "../axiosClient";
import { API_CABINET_MODEL } from "./const";

class TaskRequest extends BaseModelAPI {
    constructor() {
        super('');
    }

    async create(body) {
        return this.makeRequest(axiosClient.post, {method: API_CABINET_MODEL.methods.create_task.url, body: body });
    }
    async options() {
        return this.makeRequest(axiosClient.get, {method: API_CABINET_MODEL.methods.create_task.url});
    }
    async preferencial() {
        return this.makeRequest(axiosClient.get, {method: API_CABINET_MODEL.methods.preferencial.url});
    }
}

export default TaskRequest;
