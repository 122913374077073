import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import './DistrictList.scss'
import DistrictCard from './DistrictCard/DistrictCard'
import Loader from '../../UI/Loader/Loader'


const DistrictList = () => {
  const mapData = useSelector(state => state.map_data.mapData)
  const municipalitiesList= mapData.municipalitiesList


  return (
    <div className='district-list'>
      {mapData.municipalitiesLoading ?
        <Loader /> :
        municipalitiesList.map(mun => {
          if (mapData.isActiveMunicipality) {
            if (mapData.isActiveMunicipality === mun.id) {
              return <DistrictCard key={mun.id} municipality={mun} classes={'sticky'} />
            }
          } else {
            return <DistrictCard key={mun.id} municipality={mun} />
          }
        })
      }
    </div>
  )
}

export default DistrictList