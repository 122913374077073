import React from 'react'

import './Footer.scss'
import Fond from '../../assets/Fond.svg'
import Bik from '../../assets/Bik_logo_white.svg'

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='container'>
        <div className="footer__container">
          <div className='footer__logos'>
            <img src={Fond} alt="" />
            <img src={Bik} alt="" />
          </div>
          <div className="footer__phone-wrap">
            <a className="footer__phone" href="tel:88003012931">8 800 301 29 31</a>
            <span className="footer__phone-title">Звонок по России бесплатный</span>
            <a className="footer__phone" href="tel:84722257190">8 4722 25-71-90</a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer