import React from 'react'
import './MobileDistrictCard.scss'
import { setActiveDistrict, setActiveMunicipality } from '../../../../store/mapDataSlice'
import { useDispatch, useSelector } from 'react-redux'
import apiConfig from '../../../../api/apiConfig'

const MobileDistrictCard = ({ municipality }) => {
    const mapData = useSelector(state => state.map_data.mapData)
    const dispatch = useDispatch()
    const setMunicipality = id => dispatch(setActiveMunicipality(id))
    const setDistrict = id => dispatch(setActiveDistrict(id))

    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)

    return (
        <>
            <div className={`district-card`} onClick={() => {
                if (mapData.isActiveMunicipality) {
                    // setDistrict(null)
                    // setMunicipality(null)
                    window.location.search = `?type=${params.get('type')}`
                }
                else {
                    window.location.search = `?type=${params.get('type')}&municipality=${municipality.id}`
                    // setMunicipality(municipality.id)
                }
            }}>
                <div className="district-card__wrap">
                    <div className='district-card__img-wrap'>
                        {municipality.file &&
                            <img src={`${apiConfig.baseUrlMedia}${municipality.file}`} alt="" className='district-card__img' />
                        }
                    </div>
                    <div className='district-card__body'>
                        <span className='district-card__title'>{municipality.name}</span>
                        {mapData.isActiveMunicipality ?
                            <span className='district-card__back'>Вернуться назад</span> :
                            <span className='district-card__subtitle'>Доступно: {municipality.land_plots_count_for_sale}</span>
                        }
                    </div>
                </div>
            </div>

        </>
    )
}

export default MobileDistrictCard