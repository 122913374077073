import React from 'react'

import './Feedback.scss'
import urls from '../../urls'

const Feedback = () => {
    return (
        <div className='feedback mt-32'>
            <p className='feedback__text'><span className='_bold'>Сообщите о проблеме в БИК</span> Расскажите о проблемах с вашим жильем, мы обязательно постараемся помочь</p>
            <a href={urls.bik_bot.path} className='feedback__btn' target='_blank'>Написать</a>
        </div>
    )
}

export default Feedback