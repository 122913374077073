import React, { useState } from "react"
import DetailsText from "../../DetailsText/DetailsText"
import Modal from "../../Modal/Modal"

function CheckboxItem(props) {
    const [modalContent, setModalContent] = useState('')
    const [modalStyles, setModalStyles] = useState({})
    const [modalActive, setModalActive] = useState(false)
    const handler = (e, val) => {
        if (e.target.checked) {
            props.setValue(props.field.name, [...props.value, val])
        } else {
            props.setValue(props.field.name, props.value.filter(v => v !== val))
        }
    }

    const closeModal = (e) => {
        e.preventDefault()
        setModalActive(false)
        setTimeout(() => setModalStyles({ display: 'none' }), 300)
    }
    const openModal = (e, modal_text) => {
        e.preventDefault()
        setModalContent(modal_text)
        setModalStyles({ display: 'grid' })
        setTimeout(() => setModalActive(true), 100)
    }

    return (
        <div className={"form__item " + props.classes}>
            <span className="error-message">{(props.fieldDirty && props.error) && props.error}</span>
            <div>
                {props.field.choices.map((choice, idx) => {
                    return (
                        <>
                            <div className="checkbox-wrap form__checkbox" key={idx}>
                                <input id={choice.id} type="checkbox" onChange={e => handler(e, choice.id)} checked={props.value.some(val => val === choice.id)} />
                                <label htmlFor={choice.id}>{choice.name} <span onClick={(e) => openModal(e, choice.description)}>Подробнее</span></label>
                            </div>
                            {/* {choice.description && <DetailsText text={choice.description} />} */}
                        </>
                    )
                })}
            </div>
            <label htmlFor={props.field.name}>{props.field.label}</label>
            <Modal active={modalActive} closeModal={closeModal} styles={modalStyles}>
                <div style={{ whiteSpace: 'pre-wrap' }}>
                    <h2>Условия</h2>
                    <hr />
                    {modalContent}
                </div>
            </Modal>
        </div>
    )
}

export default CheckboxItem