import React from 'react'
import { useNavigate } from 'react-router-dom'

import './Card.scss'

const Card = ({ title, path, icon, query_string = '' }) => {
    const navigate = useNavigate()

    function openInNewTab(url) {
        var win = window.open(url, '_blank');
        win.focus();
    }
    
    return (
        <div className='service-card' onClick={() => {
            if (path.startsWith('http')){
                openInNewTab(path + query_string)
            } else {
                navigate(path + query_string)
            } 
        }}>
            <span className='service-card__title'>{title}</span>
            {icon && <img src={icon} alt={title} className='service-card__icon' />}
        </div>
    )
}

export default Card