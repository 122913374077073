import React, { useState } from "react"
import getFileName from "../../../functions/getFileName";

function FileItem(props) {
    const [loading, setLoading] = useState(false)
        , [error, setError] = useState('')

    const addField = (item) => {
        if (typeof item === 'object') {
            const new_arr = [...props.value]
            for (const url of item) {
                new_arr.push({ "file": decodeURI(url) })
            }
            props.setValue(new_arr)
        } else {
            const new_arr = [...props.value]
            new_arr.push({ "file": decodeURI(item) })
            props.setValue(new_arr)
        }

    }

    const removeField = (e, index) => {
        e.preventDefault()
        const arr = [...props.value]
        arr.splice(index, 1)
        props.setValue(arr)
    }
    const sendFile = function (e) {
        const input = e.target
            , files = [...input.files]
            , new_value = []
        setLoading(true)
        setError('')

        for (let i = 0; i < files.length; i++) {
            const form = new FormData()
            form.append('file', files[i])

            fetch('/api/incoming/upload/', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: form,
            })
                .then(response => {
                    if (!response.ok) {
                        throw Error(response.statusText)
                    }
                    return response.json()
                })
                .then(data => {
                    const url = decodeURIComponent(data)
                    new_value.push(url)
                    if (new_value.length === files.length) {
                        addField(new_value)
                        setLoading(false)
                    }
                })
                .catch(error => {
                    setLoading(false)
                    if (error.message === 'URI malformed') {
                        setError('Имя файла содержит запрещенные символы')
                    } else {
                        setError(error.message)
                    }
                })
        }
    }


    return (
        <div className={"form__item file__item " + props.classes}>
            <span className="error-message">{error ? error : ''}</span>
            <label htmlFor={"add_" + props.field.name} className="add-file__btn mt-8">{loading ? 'Загрузка...' : 'Загрузить'}</label>
            <input id={"add_" + props.field.name} onChange={e => sendFile(e)} className="d-none" type="file" />
            {props.value.length > 0 ? props.value.map((val, idx) => {
                return (
                    <div className="file__item_wrap">
                        <a className="file-label" idx={idx} href={'domain' + val.file} download={true}>{getFileName(val.file)}</a>
                        <span onClick={e => removeField(e, idx)} className="delete-file"></span>
                    </div>
                )
            }) : ''}
            <label className="" htmlFor={props.field.name}>{props.field.label}{props.field.required || props.required ? <span>*</span> : ''}</label>
        </div>
    )
}

export default FileItem