import React from 'react'


import SimplePage from '../../components/SimplePage/SimplePage'
import MapPage from '../../components/MapPage/MapPage'
import { YMaps } from '@pbe/react-yandex-maps'


const LandMapPage = ({ title }) => {
    return (
        <SimplePage>
            <h1 className='page-title'>{title}</h1>
            <MapPage />
        </SimplePage >
    )
}

export default LandMapPage