import React, { useEffect, useState } from 'react'

import './MapDropdown.scss'
import arrow from '../../../../../assets/select-arrow.svg'

const DistrictDropdown = ({ list, onClick, value }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [activeDistrict, setActiveDistrict] = useState('')

    useEffect(() => {
        if (value) {
            const a = list.filter(d => +d.id === +value)
            if (a.length > 0)
                setActiveDistrict(a[0].name)
        }
    }, [])

    return (
        <div className='map-dropdown__wrap'>
            <div className={`map-dropdown ${isOpen ? '_active' : ''}`} onClick={() => setIsOpen(!isOpen)}>
                {activeDistrict ? activeDistrict : 'Выберите микрорайон'}
                <img src={arrow} alt="" />
                </div>
            {isOpen ?
                <div className='map-dropdown__drop'>
                    {list.map(item => {
                        if (+item.land_plots_count_for_sale > 0) {
                            return <div className='microdistrict' onClick={() => {
                                setActiveDistrict(item.name)
                                setIsOpen(false)
                                onClick(item.id)
                            }}>
                                <div className='microdistrict__title'>
                                    <span className='microdistrict__name'>{item.name}</span>
                                    <span className='microdistrict__count'>{item.land_plots_count_for_sale}</span>
                                </div>
                            </div>
                        }
                    })}
                </div> :
                ''}
        </div>
    )
}

export default DistrictDropdown