import { configureStore } from '@reduxjs/toolkit'

import userDataReducer from './userDataSlice';
import microdistDataReduces from './microdistDataReduces';
import needsRerenderReducer from './needsRerenderSlice';
import filterDataReduces from './filterDataReduces';
import mapDataReducer from './mapDataSlice'

export const store = configureStore({
  reducer: {
    user_data: userDataReducer,
    mocrodist_data: microdistDataReduces,
    needsRerender: needsRerenderReducer,
    data_filter: filterDataReduces,
    map_data: mapDataReducer,
  },
})