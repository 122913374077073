import React from 'react'

import FormPage from '../../components/FormPage/FormPage'

const BuyLandMarketPage = () => {
    return (
        <FormPage>
            Купить участок в БИК Маркет
        </FormPage>
    )
}

export default BuyLandMarketPage