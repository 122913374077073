import React from 'react'

import './FormContainer.scss'

const FormContainer = ({ children }) => {
  return (
    <div className='content bg-gray'>
      <div className='form-container'>
        <div className='form-card'>
          {children}
        </div>
      </div>
    </div>
  )
}

export default FormContainer